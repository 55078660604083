import React from "react"

//components
import Layout from "../components/Layout"
import Section from "../components/Section"

const actus = () => {
  const langSelect = [
    { locale: "fr", path: "/actus" },
    { locale: "nl", path: "/news" },
  ]
  const langCurrent = "fr"
  return (
    <Layout langSelect={langSelect} langCurrent={langCurrent}>
      <Section>Actu</Section>
    </Layout>
  )
}

export default actus
